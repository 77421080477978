define("discourse/plugins/discourse-tudiabetes/discourse/templates/connectors/user-custom-preferences/discourse-tudiabetes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="controls wordpress-preferences-link">
    <a
      href="http://www.tudiabetes.org/myaccount/?module=profile"
      target="_top"
    >Edit Personal Information</a>
  </div>
  */
  {
    "id": "AmqeXz8j",
    "block": "[[[10,0],[14,0,\"controls wordpress-preferences-link\"],[12],[1,\"\\n  \"],[10,3],[14,6,\"http://www.tudiabetes.org/myaccount/?module=profile\"],[14,\"target\",\"_top\"],[12],[1,\"Edit Personal Information\"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-tudiabetes/discourse/templates/connectors/user-custom-preferences/discourse-tudiabetes.hbs",
    "isStrictMode": false
  });
});